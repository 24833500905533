import { initHandsontable } from '../../packs/handsontable';

export const initializeScopeHot = readOnly => {
  const $dataEl = $('#scope-data');
  const existingData = $dataEl.data('existing-data') || [];

  let deletedRows = [];
  const contextMenu = [
    'row_above',
    'row_below',
    'remove_row',
    'clear_column',
    '---------',
    'undo',
    'redo',
    '---------',
    'copy',
    'cut'
  ];

  const hot = initHandsontable({
    containerId: 'scope-table',
    data: existingData,
    colHeaders: ['', '', ''],
    columns: [
      {
        data: 'notes',
        type: 'text'
      },
      {
        data: 'id',
        type: 'text', readOnly: true
      }
    ],
    additionalOptions: {
      readOnly: readOnly,
      startRows: 5,
      minSpareRows: 5,
      rowHeaders: true,
      contextMenu: readOnly ? [] : contextMenu,
      dropdownMenu: false,
      filters: false,
      hiddenColumns: {
        columns: [1],
        indicators: false
      }
    }
  });

  hot.addHook('afterChange', (changes, source) => {
    if (source === 'loadData') return;

    serializeHotData(hot, deletedRows);
  });

  hot.addHook('beforeRemoveRow', (index, amount) => {
    for (let i = 0; i < amount; i++) {
      const deletedRow = hot.getSourceDataAtRow(index + i);
      if (deletedRow && deletedRow.id) {
        deletedRows.push(deletedRow.id);
      }
    }
  });

  hot.addHook('afterRemoveRow', () => {
    serializeHotData(hot, deletedRows);
  });

  serializeHotData(hot, deletedRows);

  hot.updateSettings({
    afterGetColHeader: (col, th) => {
      if (col === -1) th.innerHTML = 'Scope';
    }
  });
};

const serializeHotData = (hot, deletedRows) => {
  const $hiddenFieldsContainer = $('#scope-hidden-fields');
  $hiddenFieldsContainer.empty();

  hot.getData().forEach((row, index) => {
    if (row[0] && row[0].trim() !== '') {
      let fields = '';

      if (row[1]) fields += `<input type="hidden" name="inbound_quote[scopes_attributes][${index}][id]" value="${row[1]}">`;
      fields += `<input type="hidden" name="inbound_quote[scopes_attributes][${index}][notes]" value="${row[0]}">`;

      $hiddenFieldsContainer.append(fields);
    }
  });

  deletedRows.forEach((id, idx) => {
    const fields = `
      <input type="hidden" name="inbound_quote[scopes_attributes][${idx}][id]" value="${id}">
      <input type="hidden" name="inbound_quote[scopes_attributes][${idx}][_destroy]" value="1">
    `;
    $hiddenFieldsContainer.append(fields);
  });
};
