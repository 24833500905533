export default class ColumnValueSetter {
  setup(hotInstancesHolder) {
    this.holder = hotInstancesHolder;
    this.initializeEventListeners();
  }

  initializeEventListeners() {
    $(document).on('click.columnSetter', '.btn-column-setter', e => {
      e.preventDefault();

      const isConfirmed = confirm('Are you sure you want to proceed?');
      if (isConfirmed) {
        this.disableButtons();
        this.handleColumnSetterClick(e);
      }
    });
  }

  disableButtons() {
    const buttons = $('#settlement-populate-mbf-btn, #settlement-export-btn');
    buttons.addClass('disabled');

    setTimeout(() => {
      buttons.removeClass('disabled');
    }, 5000);
  }

  handleColumnSetterClick(e) {
    const attribute = $(e.currentTarget).data('attribute');
    const value = $(e.currentTarget).data('value');

    this.holder.getHotInstances().forEach(hotInstance => {
      const changes = [];

      for (let row = 0; row < hotInstance.countRows(); row++) {
        const id = hotInstance.getDataAtRowProp(row, 'id');
        const columns = hotInstance.getSettings().columns;
        const columnExists = columns.some(column => column.data === attribute);
        if (id && columnExists) changes.push([row, attribute, value]);
      }

      if (changes.length > 0) {
        hotInstance.batch(() => {
          hotInstance.setDataAtRowProp(changes);
        });
      }
    });
  }

  destroy() {
    $(document).off('click.columnSetter', '.btn-column-setter');
    this.hotInstances = null;
  }
}
