import SectionDataCapture from '../../load_modules/work_order/section_data_capture';
import CurrencyTotal from '../../load_modules/work_order/currency_total';
import SectionService from '../../load_modules/work_order/section_service';
import {toastErrorMessage} from '../../../packs/toast';
import Summary from '../../load_modules/work_order/summary';
import SectionOnsiteService from '../../load_modules/work_order/section_onsite_service';
import SectionDestruction from '../../load_modules/work_order/section_destruction';
import {fetchLoadWorkOrders} from '../../common/api_requests';
import ColumnValueSetter from './column_value_setter';

export default class LoadWorkOrders {
  constructor() {
    this.sections = [];
    this.summary = new Summary();
    this.columnValueSetter = new ColumnValueSetter();
  }

  setup(loadModuleIds, nonEditable) {
    loadModuleIds = (loadModuleIds || []).map(id => parseInt(id, 10));

    const $container = $('.work-orders-details');
    const $title = $('.work-orders-no-items-title');

    if (loadModuleIds.length > 0) {
      $title.hide();
      $container.show();

      fetchLoadWorkOrders(loadModuleIds)
        .done(data => {
          this.summary.setup(data);
          this.setupSections(data, loadModuleIds, nonEditable);
        })
        .fail(_response => {
          toastErrorMessage('Something went wrong. Please try again later.');
        });
    } else {
      $title.show();
      $container.hide();
    }

    $container.on('onsiteServiceChange', () => {
      fetchLoadWorkOrders(loadModuleIds)
        .done(data => {
          this.summary.destroy();
          this.summary.setup(data);
        })
        .fail(_response => {
          toastErrorMessage('Something went wrong. Please try again later.');
        });
    });

    this.columnValueSetter.setup(this);
  }

  setupSections(data, loadModuleIds, nonEditable) {
    this.sections = [
      new SectionDataCapture(),
      new SectionOnsiteService(),
      new SectionDestruction(),
      new SectionService()
    ];
    this.currencyTotal = new CurrencyTotal(this.sections);
    this.currencyTotal.setup();
    this.sections.forEach(section => {
      section.setup({load_work_orders: data}, loadModuleIds, nonEditable);
    });
  }

  getHotInstances() {
    const hotInstances = [];

    this.sections.forEach(section => {
      section.getTables().forEach(table => {
        hotInstances.push(table.getHotInstance());
      });
    });

    return hotInstances;
  }

  destroy() {
    this.sections.forEach(section => {
      section.destroy();
    });
    this.summary.destroy();
    this.columnValueSetter.destroy();
  }
}
