import fetchData from './fetch_data';
import SectionMbf from './section_mbf';
import SectionService from '../../../load_modules/work_order/section_service';
import CurrencyTotal from '../../../load_modules/work_order/currency_total';
import SectionOnsiteService from '../../../load_modules/work_order/section_onsite_service';
import SectionDataCapture from '../../../load_modules/work_order/section_data_capture';
import ColumnValueSetter from '../column_value_setter';

export default class LoadMbfs {
  constructor(mbfCompany) {
    this.mbfCompany = mbfCompany;
    this.sections = [];
    this.columnValueSetter = new ColumnValueSetter();
  }

  setup(loadModuleIds, nonEditable) {
    if (loadModuleIds.length > 0 && !nonEditable) {
      $('.mbf-rate-btn').show();
    } else {
      $('.mbf-rate-btn').hide();
    }

    if (loadModuleIds.length > 0) {
      $('.work-orders-no-items-title').hide();
      $('.mbf-report-group').show();

      fetchData(loadModuleIds, this.mbfCompany.id).then(data => {
        this.sections = [
          new SectionMbf(this.mbfCompany),
          new SectionDataCapture(this.mbfCompany),
          new SectionOnsiteService(),
          new SectionService(this.mbfCompany)
        ];
        let total = new CurrencyTotal(this.sections);
        total.setup();

        this.sections.forEach(section => {
          section.setup(data, loadModuleIds, nonEditable);
        });
      });
    } else {
      $('.work-orders-no-items-title').show();
      $('.mbf-report-group').hide();
    }

    this.columnValueSetter.setup(this);
  }

  getHotInstances() {
    const hotInstances = [];

    this.sections.forEach(section => {
      section.getTables().forEach(table => {
        hotInstances.push(table.getHotInstance());
      });
    });

    return hotInstances;
  }

  destroy() {
    if (this.sections) {
      this.sections.forEach(section => {
        section.destroy();
      });
    }
    this.sections = null;
    this.columnValueSetter.destroy();
  }
}
