import LoadMbfs from './report_details/mbf/load_mbfs';
import LoadWorkOrders from './report_details/load_work_orders';

$(document).on('turbolinks:load', () => {
  if ($('.settlement-report-details').length > 0) {
    let initReportDetails = () => {
      let mbfCompanyId = $('#mbf-company')
        .data('mbf-company-id');
      let companyName = $('#mbf-company')
        .data('mbf-company-name');

      if (mbfCompanyId) {
        return new LoadMbfs({
          id: mbfCompanyId,
          name: companyName
        });
      }
      return new LoadWorkOrders();
    };

    let isSettlementReportReadonly = () => {
      let editable = $('#settlement-report-editable').data('editable');
      if (!editable) return true;

      let status = $('#settlement_report_status').val();
      switch (status) {
        case 'sent':
          return true;
        case 'settled':
          return true;
        default:
          return false;
      }
    };

    let reportDetails = initReportDetails();
    let form = $('.settlement-report-form');

    let reloadLoadWorkOrders = () => {
      $('.work-orders-content').addClass('hidden');
      $('.loading-spinner').removeClass('hidden');

      reportDetails.destroy();
      reportDetails.setup(
        getLoadModuleIds(),
        isSettlementReportReadonly()
      );
      updateInputsByStatus();

      $('.work-orders-content').removeClass('hidden');
      $('.loading-spinner').addClass('hidden');
    };

    $('.work-orders-content').removeClass('hidden');
    $('.loading-spinner').addClass('hidden');

    let updateInputsByStatus = () => {
      let freezed = isSettlementReportReadonly();

      $('#settlement_report_load_module_ids').prop('disabled', freezed);
      $('.btn-column-setter').prop('disabled', freezed);
      if (freezed) {
        $('.settlement-report-form :input').prop('disabled', freezed);
        $('#settlement_report_status').prop('disabled', false);
        $('.select2-selection__clear').hide();
      } else {
        $('.select2-selection__clear').show();
      }
    };

    reloadLoadWorkOrders();

    form.on('settlement-report-form:updating', _e => {
      $('.work-orders-content').addClass('hidden');
      $('.loading-spinner').removeClass('hidden');
    });
    form.on('settlement-report-form:updated', _e => {
      $('.work-orders-content').removeClass('hidden');
      $('.loading-spinner').addClass('hidden');

      reloadLoadWorkOrders();
    });

    handleExchangeRateToggle();
  }
});

let handleExchangeRateToggle = () => {
  $('#exchange-rate-toggle').change(e => {
    let settlementReportId = $('.settlement-report-details')
      .data('settlement-report-id');
    let checked = $(e.currentTarget).is(':checked');

    return $.ajax({
      type: 'PATCH',
      url: `/admin/settlement_reports/${settlementReportId}.json`,
      data: {
        settlement_report: {
          include_exchange_rate: checked
        }
      }
    });
  });
};

export let getLoadModuleIds = () => {
  let ids = $('#settlement_report_load_module_ids').val() || [];

  if (typeof ids === 'string') ids = [ids];
  return ids.map(id => parseInt(id, 10));
};
