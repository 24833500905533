window.auditIndex = {
  init() {
    this.handleInitializeDatatable()
    this.handleFilterAuditDataTable()
    window.commonJS.handleCollapseLink()
  },

  handleFilterAuditDataTable() {
    $('.audit-filter-btn').on('click', function() {
      $('#audit_dt').DataTable().ajax.reload()
    })
  },

  handleInitializeDatatable() {
    $('#audit_dt').DataTable({
      searching: false,
      pageLength: 50,
      paging: true,
      info: true,
      dom: 'Bfrtip',
      order: [[1, "desc"]],
      buttons: this.exportButtons(),
      serverSide: true,
      ajax: {
        url: window.location.href,
        data: function(params) {
          params.audit = window.commonJS.handleGetFormData($('#audit-filter-form'), [])
        }
      },
      columns: [
        { data: 'id' },
        {
          data: 'show',
          orderable: false,
          render: function (data, type) {
            if (data.id) {
              let link = `/admin/audits/${data.id}`

              return `
                <a href="${link}">${data.code}</a>
              `
            } else {
              return ''
            }
          }
        },
        { data: 'audit_start' },
        { data: 'no_of_items' },
        { data: 'audited_date' },
        { data: 'audited_by' },
        { data: 'customer' },
        { data: 'received_date' },
        { data: 'received_by' },
        { data: 'receive_note' },
        { data: 'note' },
        { data: 'quickbooks_id' },
      ]
    });
  },

  exportButtons() {
    if ($('#audit_dt').data('exportable')) {
      return [
        {
          extend: 'copy',
          action: datatableCustomExportFunction
        },
        {
          extend: 'csv',
          text: 'CSV',
          titleAttr: 'CSV',
          action: datatableCustomExportFunction
        },
        {
          extend: 'excel',
          text: 'Excel',
          titleAttr: 'Excel',
          action: datatableCustomExportFunction
        },
        {
          extend: 'pdf',
          action: datatableCustomExportFunction,
          customize: function (doc) {
            Object.assign(doc.content[1].table, { widths: [25, 30, 25, 25, 25, 50, 50, 25, 50, 25, 70, 30] })
          }
        },
        {
          extend: 'print',
          action: datatableCustomExportFunction
        }
      ]
    }
    return [];
  }
}
