window.dashboardIndex = {
  init() {
    this.handleInitializeSelect2()
  },

  handleInitializeSelect2() {
    $('.select2, .select2-create').select2({
      placeholder: "Select",
      allowClear: true
    })

    $('#sales_general_filter').on('select2:select', function(e) {
      $("#filter_user_id_copy").val(
        $("#sales_user_filter").val() || $("#filter_user_id_copy").data('user-id')
      )
      $('#sale-report-form').trigger('submit')
    })

    $('#sales_general_filter').on('select2:clearing', function(e) {
      window.location.href = window.location.pathname
    })

    $('#sales_user_filter').on('select2:select', function(e) {
      $("#sales_general_filter_copy").val($("#sales_general_filter").val())
      $('#dashboard-sales-report-form').trigger('submit')
    })

    $('#sales_user_filter').on('select2:clearing', function(e) {
      window.location.href = window.location.pathname
    })
  },
}
