import {isLoadReceivingsReadOnly} from './load_module_general';
import {toastErrorMessage, toastSuccessMessage} from '../../packs/toast';

window.loadReceivingDetail = {
  init() {
    this.handleAutoPopulate();
    this.handleFileInputChange();
    this.handleClearImport();
    this.handleModalStack();
    this.handleCloseReceivingDetailModal();
    this.handleSaveLoadReceivingDetailsEvent();
    this.handleConfirmSaveData();
    this.handleImportFromBlanccoEvent();
    this.handleImportFromBlanccoBySapLoadNumberEvent();
    this.handleDisablingButtons();
  },

  enableUI() {
    this.handleDisablingButtons();
  },

  disableUI() {
    [
      $('#auto-populate'),
      $('#import-from-blancco'),
      $('#import-from-blancco-by-sap-load-number'),
      $('#save-receiving-detail-table')
    ].forEach(el => el.prop('disabled', true));
  },

  checkUniqueSerialNo(serialNumbers) {
    let unique = new Set();
    let duplicates = [];

    serialNumbers.forEach(item => {
      if (!item) return;
      if (unique.has(item)) {
        duplicates.push(item);
      } else {
        unique.add(item);
      }
    });

    if (duplicates.length !== 0) {
      toastErrorMessage(`Serial No ${duplicates.join(', ')} is duplicated.`);
    } else {
      $('#save-receiving-detail-table').removeClass('disabled');
      $('#import-from-blancco').removeClass('disabled');
    }
  },

  handleAutoPopulate() {
    $('#receiving-dialog #auto-populate').click(e => {
      let mainQty = parseInt($(`.qty-${$('#receiving-id').val()}`).text());
      let currentQty = loadReceivingDetailHot.getRowsCounter();

      if (mainQty - currentQty <= 0) {
        alert('Please increase your quantity on the main table')
      } else if (mainQty > 0) {
        let diff = mainQty - currentQty;

        $(e.target).addClass('disabled');

        $.ajax({
          type: 'POST',
          url: `/admin/load_module_receivings/${$('#receiving-dialog #receiving-id').val()}/load_module_receiving_details.js`,
          data: {
            quantity: diff
          },
          success: () => {
            window.loadReceivingDetail.handleUpdateLoadReceivingDetail();
          }
        });
      } else {
        alert('Please enter your quantity on the main table');
      }
    })
  },

  async handleUpdateLoadReceivingDetail() {
    const result = await this.handleGetLoadReceivingDetailData(1, $('#receiving-dialog #receiving-id').val());
    if (result) window.loadReceivingDetailHot.updateData(result.data);
  },

  handleFileInputChange() {
    $('#load_receiving_file').change(e => {
      $('.load-receiving-label').text(e.target.files[0].name);
    });
  },

  handleClearImport() {
    $('#import-file').click(() => {
      $('#import-modal').modal('show');
      $('.invalid-import').text('');
      $('.load-receiving-label').text('Upload CSV/Excel File');
    })
  },

  handleModalStack() {
    $(document).on('show.bs.modal', '.modal', function () {
      const zIndex = 2040 + 10 * $('.modal:visible').length;
      $(this).css('z-index', zIndex);

      setTimeout(() => {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
      });
    });
  },

  handleCloseReceivingDetailModal() {
    $('#receiving-dialog .close-receiving').click(() => {
      if ($('.receiving-detail-change').val() === 'true') {
        $('#warning-modal').modal('show');
      } else {
        $('#receiving-dialog').modal('hide');
      }
    });
  },

  handleConfirmSaveData() {
    $('.btn-confirm-yes').click(() => {
      $('#load_receiving_file').val('');

      window.loadReceiving.handleSendAjaxSaveLoadReceiving(null, false);

      $('#warning-modal').modal('hide');
      $('#receiving-dialog').modal('hide');
    })
  },

  async handleGetLoadReceivingDetailData(page = 1, receivingId) {
    return $.ajax({
      type: 'GET',
      url: `/admin/load_module_receivings/${receivingId}/load_module_receiving_details`,
      dataType: 'json',
      data: {
        page: page
      }
    });
  },

  handleInitializeLoadReceivingDetailHandsontable(page = 1, receivingId) {
    this.handleGetLoadReceivingDetailData(page, receivingId).then(function (result) {
      let hyperFormula = hyperformula.buildEmpty({
        licenseKey: 'internal-use-in-handsontable'
      });
      let readOnly = isLoadReceivingsReadOnly();

      let container = document.getElementById('receiving-detail-table');
      let options = {
        data: result.data,
        readOnly: readOnly,
        width: '100%',
        height: 'auto',
        stretchH: 'all',
        startRows: 10,
        startCols: 15,
        minSpareRows: 1,
        renderAllRows: true,
        columnSorting: true,
        licenseKey: 'non-commercial-and-evaluation',
        formulas: {
          engine: hyperFormula
        },
        contextMenu: readOnly ? [] : [
          'row_above',
          'row_below',
          'remove_row'
        ],
        colHeaders: [
          'ID', 'Item No', 'Description', 'Make', 'Unit Model', 'Serial No.', 'Port No.', 'IMEI', 'Asset Tag', 'HDD Serial', 'Other No.', 'Weight (Net)'
        ],
        columns: [
          {
            data: 'id',
            readOnly: true,
            className: 'htCenter'
          },
          {
            data: 'item_no',
            className: 'item-no',
            type: 'dropdown',
            strict: true,
            allowInvalid: false,
            source: Object.keys(result.load_item_types)
          },
          {
            data: 'description'
          },
          {
            data: 'make'
          },
          {
            data: 'unit_model'
          },
          {
            data: 'serial_no',
            className: 'serial-no'
          },
          {
            data: 'port_no'
          },
          {
            data: 'imei'
          },
          {
            data: 'asset_tag'
          },
          {
            data: 'hdd_serial'
          },
          {
            data: 'other_no'
          },
          {
            data: 'weight_net',
            type: 'numeric',
            validator: 'numeric',
            allowInvalid: false
          }
        ],
        afterSetDataAtCell: (changes, source) => {
        },
        afterLoadData: function() {
          this.runHooks('afterHotRowUpdated');
        },
        afterChange: function(changes) {
          this.runHooks('afterHotRowUpdated');

          $.each(changes, (index, value) => {
            if (value && value[1] === 'serial_no') {
              $('#save-receiving-detail-table').addClass('disabled');
              $('#import-from-blancco').addClass('disabled');

              debounce(e => {
                window.loadReceivingDetail.checkUniqueSerialNo(
                  loadReceivingDetailHot.getDataAtProp('serial_no')
                )
              }, 700)();

              return false;
            } else if (value && value[1] === 'item_no') {
              loadReceivingDetailHot.setDataAtRowProp(value[0], 'description', result.load_item_types[value[3]]);
            }
          });

          if (changes != null) {
            changes.forEach(([row, prop, oldValue, newValue]) => {
              $('.receiving-detail-change').val(oldValue !== newValue);
            })
          }

        },
        afterRemoveRow: function(index, amount, physicalRows, [source]) {
          this.runHooks('afterHotRowRemoved');
        },
        afterCreateRow: function() {
          this.render();
        }
      }

      loadReceivingDetailHot = handsontable(container, options);
      loadReceivingDetailHot.getRowsCounter = function getRowsCounter() {
        let count = this.countRows() - 1;
        return count > 0 ? count : 0;
      };
      loadReceivingDetailHot.addHook('afterHotRowUpdated', function(hot) {
        let count = this.getRowsCounter();
        loadReceivingDetail.handleIncreaseLoadReceivingQty(receivingId, count);
        loadReceivingDetail.setTotalItemsCounter(count);
      });
      loadReceivingDetailHot.addHook('afterHotRowRemoved', function() {
        let count = this.getRowsCounter();
        loadReceivingDetail.handleDecreaseLoadReceivingQty(receivingId, count);
        loadReceivingDetail.setTotalItemsCounter(count);
      });
      loadReceivingDetailHot.runHooks('afterHotRowUpdated');
    })
  },

  setTotalItemsCounter(count) {
    $('#receiving-dialog .total-items span').text(count);
  },

  handleIncreaseLoadReceivingQty(receivingId, currentAmountRow) {
    $(`#table-data tbody tr`).each(function (index) {
      if ($(this).find(`.qty-${receivingId}`).length > 0) {
        let validAmountRow = parseInt($(this).find(`.qty-${receivingId}`).text());

        if (currentAmountRow > validAmountRow) {
          loadReceivingHot.setDataAtRowProp(index, 'qty', currentAmountRow);
        }
      }
    })
  },

  handleDecreaseLoadReceivingQty(receivingId, currentAmountRow) {
    $(`#table-data tbody tr`).each(function (index) {
      if ($(this).find(`.qty-${receivingId}`).length > 0) {
        loadReceivingHot.setDataAtRowProp(index, 'qty', currentAmountRow);
      }
    })
  },

  async handleSaveLoadReceivingDetails(mode = 'save') {
    const receivingId = $('#receiving-dialog #receiving-id').val();
    return $.ajax({
      type: 'POST',
      url: `/admin/load_module_receivings/${receivingId}/load_module_receiving_details/update_load_receiving_details.json`,
      data: {
        load_receiving_detail_data: loadReceivingDetailHot.getData(),
        mode: mode
      }
    });
  },

  handleSaveLoadReceivingDetailsEvent() {
    $('#save-receiving-detail-table').click(async _e => {
      try {
        const response = await this.handleSaveLoadReceivingDetails();
        if (response.status === 'success') {
          $('.receiving-detail-change').val('false');
          await this.handleUpdateLoadReceivingDetail();
          window.loadReceiving.handleInitializeLoadReceivingHandsontable(window.loadReceiving.getCurrentPage());
          window.loadReceiving.toggleLoadReceivingWarning();
          toastSuccessMessage('Saved successfully!');
        } else {
          toastErrorMessage(response.message);
        }
      } catch (_response) {
        toastErrorMessage('Something went wrong. Please try again later.');
      }
    });
  },

  handleImportFromBlanccoEvent() {
    $('#receiving-dialog #import-from-blancco').click(async e => {
      this.disableUI();
      try {
        const saveResponse = await this.handleSaveLoadReceivingDetails('import');
        if (saveResponse.status === 'success') {
          $('.receiving-detail-change').val('false');

          await this.handleUpdateLoadReceivingDetail();

          const importResponse = await this.handleImportFromBlancco(e);
          window.loadModuleGeneral.updateBackgroundJobId(importResponse.background_job_id);
          window.loadModuleGeneral.checkBackgroundJob(importResponse.background_job_id);
        } else {
          toastErrorMessage(saveResponse.message);
        }
      } catch (_error) {
        this.enableUI();
        toastErrorMessage('Something went wrong. Please try again later.');
      }
    });
  },

  handleImportFromBlancco(_e) {
    const serials = loadReceivingDetailHot
      .getDataAtCol(5)
      .filter(x => x != null);
    const receivingId = $('#receiving-id').val();

    return $.ajax({
      type: 'POST',
      url: `/admin/load_module_receivings/${receivingId}/load_module_receiving_details/import_from_blancco.json`,
      data: {
        serial_no_list: serials
      }
    });
  },

  async handleImportFromBlanccoBySapLoadNumberEvent() {
    $('#receiving-dialog #import-from-blancco-by-sap-load-number').click(async e => {
      this.disableUI();
      try {
        await this.handleSaveLoadReceivingDetails();
        const response = await this.handleImportFromBlanccoBySapLoadNumber(e);
        window.loadModuleGeneral.updateBackgroundJobId(response.background_job_id);
        window.loadModuleGeneral.checkBackgroundJob(response.background_job_id);
      } catch (error) {
        this.enableUI();
        toastErrorMessage(`Something went wrong: ${error.message}`);
      }
    });
  },

  async handleImportFromBlanccoBySapLoadNumber(_e) {
    const receivingId =$('#receiving-id').val();
    return $.ajax({
      type: 'POST',
      url: `/admin/load_module_receivings/${receivingId}/load_module_receiving_details/import_from_blancco_by_load_no`
    });
  },

  handleDisablingButtons() {
    let readOnly = isLoadReceivingsReadOnly();

    [
      $('#auto-populate'),
      $('#import-from-blancco'),
      $('#import-from-blancco-by-sap-load-number'),
      $('#save-receiving-detail-table')
    ].forEach(el => el.prop('disabled', readOnly));
  },

  updateByLoadStatus() {
    this.handleDisablingButtons();
  },
}
