window.quoteList = {
  init() {
    this.handleFilterQuoteDataTable()
    this.handleInitializeQuoteListDatatable()
  },

  handleFilterQuoteDataTable() {
    $('.quote-filter-btn, #view_shipped_quotes_check').on('click', function () {
      $('#quote_dt').DataTable().ajax.reload()
    })
  },

  handleInitializeQuoteListDatatable() {
    $('#quote_dt').dataTable({
      searching: false,
      pageLength: 50,
      paging: true,
      info: true,
      order: [[0, "desc"]],
      dom: 'Bfrtip',
      serverSide: true,
      ajax: {
        url: window.location.href,
        data: function(params) {
          params.quote = window.commonJS.handleGetFormData($('#quote-form'), [])
          params.view_shipped = $('#view_shipped_quotes_check').is(":checked")
        }
      },
      buttons: this.exportButtons(),
      columns: [
        {
          data: 'show',
          orderable: false,
          render: function (data, type) {
            if (data.id) {
              let link = `/admin/quotes/${data.id}`

              return `
                <a href="${link}">${data.display_id}</a>
              `
            } else {
              return ''
            }
          }
        },
        { data: 'customer' },
        { data: 'required_date' },
        { data: 'quote_date' },
        { data: 'status' },
        { data: 'sale_person' },
        { data: 'sub_total' },
        { data: 'tax' },
        { data: 'total' },
        {
          data: 'destroy',
          orderable: false,
          render: function (data, type) {
            if (data.can_destroy) {
              let link = `/admin/quotes/${data.id}`

              return `
                <a href="${link}" class="btn code-red btn-xs" data-method='delete' data-confirm="Are you sure?">
                  <i class='fas fa-trash-alt'></i>
                </a>
              `
            } else {
              return ''
            }
          }
        }
      ]
    })
  },

  exportButtons() {
    if ($('#quote_dt').data('exportable')) {
      return [
        {
          extend: 'copy',
          action: datatableCustomExportFunction
        },
        {
          extend: 'csv',
          text: 'CSV',
          titleAttr: 'CSV',
          action: datatableCustomExportFunction
        },
        {
          extend: 'excel',
          text: 'Excel',
          titleAttr: 'Excel',
          action: datatableCustomExportFunction
        },
        {
          extend: 'pdf',
          action: datatableCustomExportFunction
        },
        {
          extend: 'print',
          action: datatableCustomExportFunction
        }
      ];
    }
    return [];
  }
}
