import {editableTransferItemsFormTable, initTransferItemsFormTable} from './transfer_items_table';
import {initHandsontable, updateHandsontableData} from '../../packs/handsontable';
import {toastErrorMessage} from '../../packs/toast';

let transferItemHot;
let searchTransferItemsDataHot;
let dropdownItemOptions;
let transferItemsData;

window.transferForm = {
  init() {
    window.commonJS.handleInitializeSelect2();
    window.commonJS.handleCollapseLink();

    this.handleInitializeDatepicker();
    $('#transfer_transferred_on_alternate').datepicker(
      'option',
      'altField',
      '#transfer_transferred_on'
    );
    $('#transfer_eta_alternate').datepicker(
      'option',
      'altField',
      '#transfer_eta'
    );
    this.handleRemoveTransferItemField();
    this.handleResetTransferItemModal();
    this.handleInitializeTransferItemHansontable();
    this.handleSaveTransfer();
    this.handleSaveTransferAndOverwrite();
    this.handleTransferTypeChange();
    this.searchTransferItemDataEvents();
    this.addTransferItemEvents();
    this.handleVendorAndInboundCustomerOnResale();
  },

  addTransferItemEvents() {
    let itemIds;

    $('.js-transfer-add-visible').click(e => {
      e.preventDefault();
      if (!searchTransferItemsDataHot) {
        toastErrorMessage('Nothing To Select');
        return;
      }
      itemIds = searchTransferItemsDataHot.getDataAtCol(1).filter(Number);
      if (itemIds.length > 0) {
        this.addItemsToTransferFormTable(this.findItemByIds(itemIds));
        $('#add-transfer-items-modal').modal('toggle');
        this.clearModalData();
      } else {
        toastErrorMessage('Nothing To Select');
      }
    })

    $('.js-transfer-add-selected').click(e => {
      e.preventDefault();

      if (!searchTransferItemsDataHot) {
        toastErrorMessage('Nothing To Select');
        return;
      }

      let filters = searchTransferItemsDataHot.getPlugin('filters');

      filters.addCondition(0, 'eq', [true]);
      filters.filter();

      itemIds = searchTransferItemsDataHot.getDataAtCol(1).filter(Number);
      if (itemIds.length > 0) {
        this.addItemsToTransferFormTable(this.findItemByIds(itemIds));
        $('#add-transfer-items-modal').modal('toggle');
        this.clearModalData();
      } else {
        filters.clearConditions();
        filters.filter();
        toastErrorMessage('Nothing Selected');
      }
    })
  },

  addItemsToTransferFormTable(data) {
    if (transferItemHot) {
      updateHandsontableData(transferItemHot, data);
    } else {
      initTransferItemsFormTable(data, dropdownItemOptions);
    }
  },

  initTransferItemsFormTable(data, dropdownOptions = {}) {
    transferItemHot = editableTransferItemsFormTable(
      data,
      'transfer-items-table',
      [],
      dropdownOptions
    );
  },

  findItemByIds(selectedIds) {
    return transferItemsData.filter(item => selectedIds.includes(item.load_inventory_code));
  },

  clearModalData() {
    $('#result-section').html('');
    $('.load_inventory_search').trigger('reset');
    $('.load_inventory_search .select2').trigger('change');
    searchTransferItemsDataHot.updateData([]);
  },

  searchTransferItemDataEvents () {
    $('.js-submit-transfer-item-search').click(e => {
      e.preventDefault();

      this.getTransferItemData().then(responce => {
        this.setMoreButton(responce.pagination);
        $('#result-section').html('');
        transferItemsData = responce.data;
        dropdownItemOptions = responce.dropdown_options;
        this.initSearchTransferItemsHot(responce.data);
      });
    });

    $('.js-more-transfer-items-button').click(e => {
      e.preventDefault();

      this.getTransferItemData(false).then(responce => {
        this.setMoreButton(responce.pagination);
        transferItemsData = [...transferItemsData, ...responce.data];
        updateHandsontableData(searchTransferItemsDataHot, responce.data);
      });
    });
  },

  setMoreButton(paginationData) {
    if (paginationData.current_page !== paginationData.last_page) {
      $('.js-more-transfer-items-button').show();
      $('.load_inventory_search').attr('page', paginationData.next_page);
    } else {
      $('.js-more-transfer-items-button').hide();
    }
  },

  getTransferItemData(refresh = true) {
    let itemsSearchForm = $('.load_inventory_search');
    let searchData = itemsSearchForm.serializeObject();

    if (transferItemHot) {
      searchData['q']['id_not_in'] = transferItemHot.getDataAtCol(7)
        .filter(Number);
    }

    if (!refresh) {
      searchData['page'] = itemsSearchForm.attr('page') || 1;
    }

    return $.ajax({
      url: itemsSearchForm.attr('action'),
      type: 'POST',
      data: searchData
    });
  },

  initSearchTransferItemsHot(data) {
    searchTransferItemsDataHot = initHandsontable({
      containerId: 'result-section',
      data: data,
      colHeaders: [
        '',
        'ID',
        'Type',
        'Weight',
        'UOM',
        'Serial No',
        'Load ID',
        'Qualified Type',
        'Customer'
      ],
      columns: [
        {
          data: 'selected',
          type: 'checkbox',
          className: 'htCenter'
        },
        {
          data: 'load_inventory_code',
          editor: false,
          skipColumnOnPaste: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'types',
          editor: false,
          skipColumnOnPaste: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'weight',
          editor: false,
          skipColumnOnPaste: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'uom',
          editor: false,
          skipColumnOnPaste: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'serial_no',
          editor: false,
          skipColumnOnPaste: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'load_code',
          editor: false,
          skipColumnOnPaste: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'status',
          editor: false,
          skipColumnOnPaste: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'acquired_from',
          editor: false,
          skipColumnOnPaste: true,
          className: 'htCenter',
          renderer: 'html'
        }
      ],
      additionalOptions: {
        filters: true
      }
    });
  },

  handleInitializeDatepicker() {
    $('.wrapper').on('change', '.datepicker', e => {
      if ($(e.currentTarget).val().length > 0) {
        $(e.currentTarget)
          .siblings('.clear-datepicker')
          .removeClass('hidden');
      }
    });

    $('.datepicker-wrapper').on('click', '.clear-datepicker', e => {
      $(e.currentTarget).siblings('.datepicker')
        .datepicker(
          'setDate',
          null
        );
      $(e.currentTarget).addClass('hidden');
    });

    $('.datepicker').datepicker({
      todayHighlight: true,
      orientation: 'auto top',
      dateFormat: 'mm-dd-yy'
    });

    $('.datepicker').datepicker(
      'option',
      'altFormat',
      'dd-mm-yy'
    );
  },

  // Transfer Items

  handleRemoveTransferItemField() {
    $('.transfer-items').click('.remove-fields', e => {
      $(e.currentTarget).parents('tr').remove();
    });
  },

  handleResetTransferItemModal() {
    $('#add-transfer-items-modal').on('show.bs.modal', () => {
      let loadReceivingDetailIdCol = 7;

      $('.result-section').empty();
      $('#add-transfer-items-modal form .select2, #transfer_item_weigh').val([]).trigger('change');
      $('#add-transfer-items-modal #transfer-item-ids').val('');
      $('#add-transfer-items-modal input[type="text"]').val('');
      $('#add-transfer-items-modal #already_exist_ids').val(
        transferItemHot.getDataAtCol(loadReceivingDetailIdCol).filter(element => {
          return element !== null;
        })
      );
    });
  },

  handleInitializeTransferItemHansontable(page = 1) {
    this.handleGetTransferItemData(page).then(response => {
      $('.ht_master.handsontable:first').remove();

      transferItemHot = initTransferItemsFormTable(
        response.data,
        'transfer-items-table',
        $('#transfer-items-table').data('editable'),
        response.hidden_columns
      );
    });
  },

  async handleGetTransferItemData(page = 1) {
    const transfer_id = $('#transfer_id').val();

    if (!transfer_id) {
      return Promise.resolve({ data: [], hidden_columns: [] });
    }

    $('#transfer-items-table').addClass('overlap');

    return $.ajax({
      type: 'GET',
      url: `/admin/transfers/${transfer_id}/transfer_items`,
      dataType: 'json',
      data: {
        id: transfer_id,
        page: page
      },
      success: () => {
        $('#transfer-items-table').removeClass('overlap');
      }
    });
  },

  handleSaveTransfer(showToastMessage = true) {
    $('.save-transfer').click(_e => {
      this.sendTransfer(showToastMessage);
    });
  },

  sendTransfer(showToastMessage, force_overwrite = false) {
    $('.save-transfer').addClass('disabled')
    $('.transfer-form').addClass('overlap');

    let transfer_id = $('#transfer_id').val() || null;

    let data = {
      transfer_item_data: transferItemHot.getData(),
      destroy_array: $('#destroy_transfer_item_arr').val(),
      show_toast_message: showToastMessage
    };

    $.each($('#transfer-info-section input, select, #hidden-columns, .actual-weight input'), (index, element) => {
      data[element.name] = element.value;
    });

    $.ajax({
      type: 'PATCH',
      url: `/admin/transfers/${transfer_id}.js?force_overwrite=${force_overwrite}`,
      data: data,
      error: (_jqXHR, textStatus, _errorThrown) => {
        toastErrorMessage(`Request failed: ${textStatus}`);
      }
    });
  },

  handleSaveTransferAndOverwrite() {
    $('#overwrite-audit-confirmed').click(_e => {
      this.sendTransfer(true, true);
    });
  },

  handleTransferTypeChange() {
    let transfer_type_select = $('#transfer_transfer_type');

    transfer_type_select.change(e => {
      let selected = $(e.target).val();
      let elementsToToggle = [
        $('#transfer-tax-block'),
        $('#transfer-vendor-block'),
        $('#transfer-inbound-customer-block'),
      ];

      if (selected === 'resale') {
        elementsToToggle.forEach(el => el.show());
      } else {
        elementsToToggle.forEach(el => el.hide());

        [
          $('#transfer_tax_id'),
          $('#transfer_vendor_id'),
          $('#transfer_inbound_customer_id'),
        ].forEach(el => el.val(null).trigger('change'))
      }
    });
    transfer_type_select.trigger('change');
  },

  handleVendorAndInboundCustomerOnResale() {
    let inboundCustomerEl = $('#transfer_inbound_customer_id');
    let vendorEl = $('#transfer_vendor_id');

    [
      [inboundCustomerEl, vendorEl],
      [vendorEl, inboundCustomerEl],
    ].forEach(([resetBy, toReset]) => {
      resetBy.change(_e => {
        if (resetBy.val() && toReset.val()) {
          toReset.val('');
          toReset.trigger('change');
        }
      });
    });
  }
};
