export default class HotBase {
  get subtotals() {
    return this._subtotals;
  }

  setup(currencies) {
    this.currencies = currencies;
    this._subtotals = {};
    currencies.forEach(currency => {
      this._subtotals[currency] = 0;
    });
    this._beforeChangeCallbacks = [];
  }

  getHotInstance() {
    return this.hot;
  }

  onStandaloneMode() {
    $('.mbf-rate-btn').show();
  }

  get beforeChangeCallbacks() {
    return this._beforeChangeCallbacks;
  }

  addBeforeChangeCallbacks(callback) {
    this._beforeChangeCallbacks.push(callback);
  }

  onAfterChange(changes) {
    this.detectWeightChange(changes) &&
      this.onWeightChanged();
  }

  detectWeightChange(changes) {
    return changes.some(([_row, prop, _oldValue, _newValue]) => {
      return ['weight', 'uom'].includes(prop);
    });
  }

  listenerOnWeightChange(callback) {
    this.onWeightChanged = callback;
  }

  listenerOnSubtotalChange(callback) {
    this.onSubtotalChanged = callback;
  }

  updateSubtotals() {
    this.currencies.forEach(currency => {
      this._subtotals[currency] = 0;
    });
    for (let i = 0; i < this.hot.countSourceRows(); i++) {
      let currency = this.hot.getDataAtRowProp(i, 'currency');
      let total = this.hot.getDataAtRowProp(i, 'total');
      this._subtotals[currency] += Number(total);
    }
    this.onSubtotalChanged();
  }

  destroy() {
    if (this.hot) {
      this.hot.destroy();
      this.hot = null;
      this.onSubtotalChanged = null;
      this._beforeChangeCallbacks = null;
    }
  }
}
